<template>
    <st-page
        :title="title"
        pageCustomClass="st-page-archive-documents"
    >
        <!-- <template #toolbar>
            <st-button
                variant="light"
                class="st-btn-text-primary"
                :callback="downloadDocument"
            >
                <i class="fa fa-download"></i>
                {{$t('ARCHIVE.BUTTONS.DOWNLOAD')}}
            </st-button>
        </template> -->
        <st-page
            :title="$t('ARCHIVE.DOCUMENT.TITLE_VIEW_ARCHIVE')"
        >
            <template #toolbar>
                <st-button
                    variant="light"
                    class="st-btn-text-primary font-size-h6 px-5 py-3 my-3 mr-3"
                    :callback="cancel"
                >
                    <i class="fas fa-times svg-icon-primary"/>
                    <span>{{$t('GENERAL.BUTTON.CANCEL')}}</span>
                </st-button>

            </template>
            <document
                ref="document"
                :documentId="$route.params.id"
                :folder="$route.params.folder"
                :workspace="$route.params.workspace"
            >
            </document>
        </st-page>
    </st-page>
</template>

<script>
    import Document from '../components/document/DocumentBody';
    export default {
        name: 'ArchiveDocument',
        components: {Document},
        computed: {
            title() {
                return `${this.$route.params.titlePath} /
                        ${this.$route.params.document.documentName}`;
            },
        },
        methods: {
            downloadDocument() {
                // TODO: Finish once BE is done
            },
            cancel() {
                this.$router.replace({
                    name: 'archive-documents',
                    params: {
                        id: this.$route.params.id,
                        folder: this.$route.params.folder,
                        workspace: this.$route.params.workspace
                    }
                });
            }
        },
    }
</script>
